/* 
File: sections.js
Description: called from Sections.vue and RoadsList.vue, gets section data for given road 
             from DB and passes as a list back to the caller.
*/
import Api from '@/api/sections';
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    list: [],
    sectionGeometryList: [],
  },

  actions: {
  /*--------- Sections  ---------------------*/
  async LOAD_SECTIONS_LIST({ commit }, values) {
      const { region_id, road_id, deu_id, for_map } = values
      if (!region_id && !road_id && !for_map) {
        commit('SET_SECTIONS_LIST', [])
        return 'success'
      }

      return await handleApiCall({
        request: Api.load_sections_all(region_id, road_id, deu_id), 
        caller: 'LOAD_SECTIONS_LIST',
        commit, mutation: 'SET_SECTIONS_LIST'
      });
    },

    async LOAD_SECTION_BY_ID({ commit }, sid) {
      return await apiCall(Api.load_section_by_id(sid), 'LOAD_SECTION_BY_ID');
    },

    async GET_REGION_ROAD_FROM_SECTION({ commit }, id) {
      return await apiCall(Api.get_region_road_id_from_section(id), 'GET_REGION_ROAD_FROM_SECTION');
    },

    async ADD_SECTION({ commit }, section) {
      return await apiCall(Api.add_section(section), 'ADD_SECTION', 201);
    },

    async UPD_SECTION({ commit }, { id, section }) {
      return await apiCall(Api.upd_section(id, section), 'UPD_SECTION');
    },

    async DEL_SECTION({ commit }, section_id) {
      return await apiCall(Api.del_section(section_id), 'DEL_SECTION');
    },

  /*--------- Section geometry  ---------------------*/
  async LOAD_SECTION_GEOMETRY_ALL({ commit }, section_id) {
    return await handleApiCall({
      request: Api.load_rdb_section_geometry_all(section_id), 
      caller: 'LOAD_SECTION_GEOMETRY_ALL',
      commit, mutation: 'SET_SECTION_GEOMETRY'
    });
  },

  async LOAD_SECTION_GEOMETRY_BY_ID({ commit }, geometry_id) {
    return await apiCall(Api.load_rdb_section_geometry_by_id(geometry_id), 'LOAD_SECTION_GEOMETRY_BY_ID');
  },

  async ADD_SECTION_GEOMETRY({ commit }, geometry) {
    return await apiCall(Api.add_rdb_section_geometry(geometry), 'ADD_SECTION_GEOMETRY', 201);
  },

  async UPD_SECTION_GEOMETRY({ commit }, { id, geometry }) {
    return await apiCall(Api.upd_rdb_section_geometry(id, geometry), 'UPD_SECTION_GEOMETRY');
  },

  async DEL_SECTION_GEOMETRY({ commit }, geometry_id) {
    return await apiCall(Api.del_rdb_section_geometry(geometry_id), 'DEL_SECTION_GEOMETRY');
  }
},

  mutations: {
    SET_SECTIONS_LIST(state, list) {
      state.list = [...list];
    },
    SET_SECTION_GEOMETRY(state, list) {
      state.sectionGeometryList = [...list];
    },
  },

  getters: {
    roadsList: (state) => {
      const uniqueIds = new Set();
      return state.list.reduce((acc, item) => {
        if (!uniqueIds.has(item.road_id)) {
          uniqueIds.add(item.road_id);
          acc.push({ id: item.road_id, name: item.road, deu_id: item.deu_id });
        }
        return acc;
      }, [])
      .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
    },

    roadsListFiltered: (state, getters) => (deu_id) => {
      const res = getters.roadsList.filter(item =>
        (!deu_id || item.deu_id === deu_id)  // Проверка на ДЭУ (если передано)
      )
      res.unshift({ id: 0, name: '', deu_id: null })   // Add null value to the beginnig
      return res
    },

    sectionsFiltered: (state) => (deuLabel, region_id, deu_id, road_id) => {
      return state.list.filter(item =>
        (!region_id || item.region_id === region_id) &&  // Проверка на регион (если передан)
        (!deu_id || item.deu_id === deu_id) &&  // Проверка на ДЭУ (если передано)
        (!road_id || item.road_id === road_id)           // Проверка на дорогу (если передана)
      )
      .map(item => ({...item, deu: `${deuLabel}-${item.deu}`}))
    },

    lastGeometry: (state) => {
      const len = state.sectionGeometryList.length
      return len > 0 ? state.sectionGeometryList[len - 1] : {}
    }
  }
};