/*
File: store/rbac/users.js
Description: API connections for managing users.
*/
import Api from '@/api/users_groups'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    list: [],
    groups: [],
    isUserOper: false
  },
  actions: {
    async LOAD_USERS_LIST({ commit }) {
      return await handleApiCall({
        request: Api.load_users_list(),
        caller: 'LOAD_USERS_LIST',
        commit,
        mutation: 'SET_USERS_LIST'
      });
    },

    // TODO: Move to the dropdowns.js
    async LOAD_USERS_GROUPS({ commit, getters }) {
      const res = await apiCall(Api.load_users_groups(), 'LOAD_USERS_GROUPS');
      const locale = getters.locale_active
      const group_list = res.map(group => ({ id: group.id, name: group[`name_${locale}`] }))
      commit('SET_USERS_GROUPS', group_list)
      return 'success'
    },

    async LOAD_USER_BY_ID({ commit }, id) {
      return await apiCall(Api.load_user_by_id(id), 'LOAD_USER_BY_ID');
    },

    async ADD_USER({ commit }, user) {
      return await apiCall(Api.add_user(user), 'ADD_USER', 201);
    },

    async UPD_USER({ commit }, payload) {
      return await apiCall(Api.upd_user(id, user), 'UPD_USER');
    },

    async DEL_USER({ commit }, id) {
      return await apiCall(Api.del_user(id), 'DEL_USER');
    }
  },

  mutations: {
    SET_USERS_LIST(state, list) {
      state.list = [...list]
    },
    SET_USERS_GROUPS(state, list) {
      state.groups = [...list]
    },
  },
  getters: {}
}
