/*
File: api/sections.js
Description: API connections for the async functions for Sections, Section geometry and Homogeneous Sections.
*/
import Api_ref from '@/api'
const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getLangQuery = () => {
  const lang = global.localStorage["mps-locale"] || "en";
  return `?lang=${lang}`;
};

export default {
  /*--------- Sections  ---------------------*/
  load_sections_all(region_id, road_id, deu_id) {
    let apiStr = `/rdb/section/all${getLangQuery()}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (road_id) apiStr += `&road_id=${road_id}`
    if (deu_id) apiStr += `&deu_id=${deu_id}`

    return Api.get(apiStr, getHeaders());
  },

  load_section_by_id(id) {
    return Api.get(`/rdb/section/${id}${getLangQuery()}`, getHeaders());
  },

  get_region_road_id_from_section(id) {
    return Api.get(`/rdb/section/region_road/${id}`, getHeaders());
  },

  add_section(section) {
    return Api.post(`/rdb/section`, section, getHeaders());
  },

  upd_section(id, section) {
    return Api.put(`/rdb/section/${id}`, section, getHeaders());
  },

  del_section(section_id) {
    return Api.delete(`/rdb/section/${section_id}`, getHeaders());
  },

  /*--------- Section geometry  ---------------------*/
  load_rdb_section_geometry_all(section_id) {
    let apiStr = `/rdb/section_geometry/all${getLangQuery()}&section_id=${section_id}`
    return Api.get(apiStr, getHeaders());
  },

  load_rdb_section_geometry_by_id(geometry_id) {
    return Api.get(`/rdb/section_geometry/${geometry_id}${getLangQuery()}`, getHeaders());
  },

  add_rdb_section_geometry(geometry) {
    return Api.post(`/rdb/section_geometry`, geometry, getHeaders());
  },

  upd_rdb_section_geometry(geometry_id, geometry) {
    return Api.put(`/rdb/section_geometry/${geometry_id}`, geometry, getHeaders());

  },
  del_rdb_section_geometry(geometry_id) {
    return Api.delete(`/rdb/section_geometry/${geometry_id}`, getHeaders());
  },
  
  /*---------  Homogeneous sections ---------------------*/
  load_hs_lists(year, is_approved) {
    let apiStr = `/homogeneous_section_list/all${getLangQuery()}`
    if (year) apiStr += `&year=${year}`
    if (is_approved!=null) apiStr += `&is_approved=${is_approved}`   // !==null as we accept 0 as the value

    return Api.get(apiStr, getHeaders());
  },

  get_hs_list_by_id(homogeneous_section_list_id) {
    return Api.get(`/homogeneous_section_list/${homogeneous_section_list_id}${getLangQuery()}`, getHeaders());
  },

  load_all_hs(homogeneous_section_list_id) {
    return Api.get(`/homogeneous_section_list/all_hs/${homogeneous_section_list_id}`, getHeaders());
  },

  generate_hs(year_from, year_to, min_hs_length, condition_index_threshold) {
    let apiStr = '/homogeneous_section_list/generate_hs/?'
    if (year_from!=null) apiStr += `year_from=${year_from}`
    if (year_to!=null) apiStr += `&year_to=${year_to}`
    if (min_hs_length!=null) apiStr += `&min_hs_length=${min_hs_length}`
    if (condition_index_threshold!=null) apiStr += `&condition_index_threshold=${condition_index_threshold}`

    return Api.post(apiStr, getHeaders());
  },

  del_hs_list(homogeneous_section_list_id) {
    return Api.delete(`/homogeneous_section_list/${homogeneous_section_list_id}`, getHeaders());
  },

  approve_hs_list(homogeneous_section_list_id) {
    return Api.put(`/homogeneous_section_list/approve/${homogeneous_section_list_id}`, getHeaders());
  },

  cancel_hs_list(homogeneous_section_list_id) { 
    return Api.put(`/homogeneous_section_list/cancel_approval/${homogeneous_section_list_id}`, getHeaders());
  }
}