/* 
File: condition.js
Description: API connections for the async functions for Condition data and for Condition Index Criteria.
*/
import Api_ref from '@/api'
const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getLangQuery = () => {
  const lang = global.localStorage["mps-locale"] || "en";
  return `?lang=${lang}`;
};

export default {
  /*--------- Condition ---------------------*/
  load_condition_all(params) {
    const queryString = Object.entries(params)
      .filter(([, value]) => value !== undefined && value !== null) // Убираем пустые значения
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`) // Кодируем значения
      .join('&');

    return Api.get(`/rdb/data100/all${getLangQuery()}&${queryString}`, getHeaders());
  },

  load_condition_by_id(data_100_id) {
    return Api.get(`/rdb/data100/${data_100_id}${getLangQuery()}`, getHeaders());
  },

  // get Condition data from data100 by provided x and y (clicked on the map)
  get_condition_data_by_coords(x, y) {
    return Api.get(`/rdb/data100/get_by_x_y?x=${x}&y=${y}`, getHeaders());
  },

  add_condition(condition) {
    return Api.post(`/rdb/data100`, condition, getHeaders());
  },

  upd_condition(id, condition) {
    return Api.put(`/rdb/data100/${id}`, condition, getHeaders());
  },

  del_condition(data_100_id) {
    return Api.delete(`/rdb/data100/${data_100_id}`, getHeaders());
  },

  /*--------- Condition Index Criteria ---------------------*/
  load_condition_index_criteria_all() {
    return Api.get(`/condition_index_criteria/all${getLangQuery()}`, getHeaders());
  },

  load_condition_index_criteria_pivot() {
    return Api.get(`/condition_index_criteria/pivot${getLangQuery()}`, getHeaders());
  },

  load_condition_index_criteria_by_id(id) {
    return Api.get(`/condition_index_criteria/${id}${getLangQuery()}`, getHeaders());
  },

  add_condition_index_criteria(condition) {
    return Api.post(`/condition_index_criteria`, condition, getHeaders());
  },

  upd_condition_index_criteria(id, condition) {
    return Api.put(`/condition_index_criteria/${id}`, condition, getHeaders());
  },

  del_condition_index_criteria(id) {
    return Api.delete(`/condition_index_criteria/${id}`, getHeaders());
  },
}