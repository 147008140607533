/*
File: api/dropdowns.js
Description: API connections for the async functions for filling dropdown lists.
*/
import Api_ref from '@/api'

const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getLangQuery = () => {
  const lang = global.localStorage["mps-locale"] || "en";
  return `?lang=${lang}`;
};

export default {
  // list of roads for dropdown
  load_roads_dropdown_list(region_id) {
    let apiStr = `/rdb/road/list${getLangQuery()}`
    if (region_id) apiStr += `&region_id=${region_id}`

    return Api.get(apiStr, getHeaders());
  },

  // list of sections for dropdown
  load_sections_dropdown_list(region_id, road_id) {
    let apiStr = `/rdb/section/list${getLangQuery()}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (road_id) apiStr += `&road_id=${road_id}`

    return Api.get(apiStr, getHeaders());
  },

  // list of years for dropdown in budgeting works
  load_works_years_dropdown_list() {
    return Api.get(`/rdb/section_maintenance_plan/years`, getHeaders());
  },

  /*--------- Survey years for dropdowns ---------------------*/
  load_years_dropdown_list() {
    return Api.get(`/rdb/data100/list_survey_years${getLangQuery()}`, getHeaders());
  },

  load_import_types_dropdown_list() {
    return Api.get(`/rdb/import_type/all${getLangQuery()}`, getHeaders());
  },
}