/*
File: api/import.js
Description: API connections for the async functions for bulk importing.
*/
import Api_ref from '@/api'
const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getBlobHeaders = () => ({
  responseType: "blob",
  headers: {
    ...type_json
  }
});

const getFormHeaders = () => ({
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});


export default {
  /*--------- Roads bulk importing  ---------------------*/
  get_rdb_road_import_template() {
    return Api.get('/rdb/road/import_template', getBlobHeaders());
  },

  import_rdb_road(import_file) {
    return Api.post('/rdb/road/import', import_file, getFormHeaders());
  },
  
  /*--------- Sections bulk importing  ---------------------*/
  get_rdb_section_import_template() {
    return Api.get('/rdb/section/import_template', getBlobHeaders());
  },

  import_rdb_section(import_file) {
    return Api.post('/rdb/section/import', import_file, getFormHeaders());
  },

  /*--------- IRI/Rutting bulk importing  ---------------------*/
  get_rdb_iri_import_template() {
    return Api.get('/rdb/data100/import_template/iri', getBlobHeaders());
  },

  get_rdb_rutting_import_template() {
    return Api.get('/rdb/data100/import_template/rutting', getBlobHeaders());
  },

  import_rdb_data100(import_files, section_id) {
    return Api.post(`/rdb/data100/import?section_id=${section_id}`, import_files, getFormHeaders());
  },

  /*--------- Logs of bulk importing  ---------------------*/
  get_import_log_all(batch_id) {
    return Api.get(`/rdb/import_log/all?batch_id=${batch_id}`, getHeaders());
  },

  get_import_log_by_id(log_id) {
    return Api.get(`/rdb/import_log/${log_id}`, getHeaders());
  },
}