/* 
File: dropdowns.js
Description: returns lists for filling dropdowns.
*/
import Api from '@/api/dropdowns';
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

function filterAndSortList(data, fields, filters = {}, showAll = false, prefix = '') {
  let list = data
    .filter(item => {
      // Return all item or filtered if provided
      return Object.keys(filters).length === 0 ||
        Object.keys(filters).every(key => filters[key] === undefined || item[key] === filters[key])
    })
    .map(item => ({
      id: item[fields.id],
      description: prefix ? `${prefix}-${item[fields.description]}` : item[fields.description]
    }))
    // Order by description field
    .sort((a, b) => a.description.localeCompare(b.description, undefined, { sensitivity: 'base' }))

  if (showAll) { // Add empty row in the beginning if showAll
    list.unshift({ id: 0, description: '' })
  }

  return list
}

export default {
  state: {
    roadnetwork: [],
    regions: [],
    deus: [],
    road_classes: [],
    roads: [],
    sections: [],
    work_categories: [],
    invtype_events: [],
    years: [],
    budget_years: [],
    import_types: [],
    roles: [],
    users: []
  },

  actions: {
    async GET_NETWORK_DROPDOWN({ commit }, show_all = true) {
      const rn = [{ fk_rn: 1, roadnetwork: "Preliminary KGZ road network" }]

      if (show_all) rn.unshift({ fk_rn: 0, roadnetwork: '' })
      commit('SET_NETWORK_LIST', rn)
      return 'success'
    },

    async GET_REGIONS_DROPDOWN({ dispatch, commit }, values) {
      const { rn = 1, show_all = true } = values
      let regionsList = filterAndSortList(
        await dispatch('LOAD_REGIONS_LIST', true),
        { id: 'region_id', description: 'region_description' },  // fields for mapping
        { fk_rn: rn, active: true },  // filters
        show_all
      )
      commit('SET_REGION_LIST', regionsList)
      return 'success'
    },

    async GET_DEUS_DROPDOWN({ dispatch, commit }, values) {
      const { region_id, show_all, prefix } = values
      const res = filterAndSortList(
        await dispatch('LOAD_DEU_LIST', {region_id, forDropdown: true}),
        { id: 'deu_id', description: 'description' },  // fields for mapping
        {},  // filters
        show_all,
        prefix
      )

      commit('SET_DEU_LIST', res)
      return 'success'
    },

    async GET_ROADS_CLASSES_DROPDOWN({ commit }, show_all = true) {
      const road_classes = /*lang === 'en' ? [
        { road_class_id: "Zh", road_class_name: "Zh" },
        { road_class_id: "M", road_class_name: "M" },
        { road_class_id: "EM", road_class_name: "EM" }
      ] : */[
          { road_class_id: "Ж", road_class_name: "Ж" },
          { road_class_id: "М", road_class_name: "М" },
          { road_class_id: "ЭМ", road_class_name: "ЭМ" }
        ]

      if (show_all) { // Add empty row in the beginning if showAll
        road_classes.unshift({ road_class_id: '', road_class_name: '' })
      }

      commit('SET_ROAD_CLASSES_LIST', road_classes)
      return 'success'
    },

    async GET_ROADS_DROPDOWN({ commit }, values) {
      const { region_id, show_all } = values
      const res = filterAndSortList(
        await apiCall(Api.load_roads_dropdown_list(region_id), 'GET_ROADS_DROPDOWN'),
        { id: 'road_id', description: 'road_description' },  // fields for mapping
        {},  // filters
        show_all
      )

      commit('SET_ROADS_LIST', res)
      return 'success'
    },

    async GET_SECTIONS_DROPDOWN({ commit }, values) {
      const { region_id, road_id, show_all } = values
      const res = filterAndSortList(
        await apiCall(Api.load_sections_dropdown_list(region_id, road_id), 'GET_SECTIONS_DROPDOWN'),
        { id: 'section_id', description: 'section_description' },  // fields for mapping
        {},  // filters
        show_all
      )

      commit('SET_SECTIONS_LIST', res)
      return 'success'
    },

    async GET_WORK_CATEGORIES_LIST({ commit, dispatch }, show_all) {
      const res = filterAndSortList(
//        await apiCall(Api.load_work_categories_dropdown_list(), 'GET_WORK_CATEGORIES_LIST'),
        await dispatch('LOAD_WORK_CATEGORIES_LIST', true),
        { id: 'work_category_id', description: 'description' },  // fields for mapping
        {},  // filters
        show_all
      )
      commit('SET_CATEGORIES_LIST', res)
      return 'success'
    },

    // TODO: Review it
    async GET_INVTYPE_EVENT_DROPDOWN({ commit, dispatch }) {
      dispatch('LOAD_INVENTORY_EVENTS_LIST').then(() => {
        const eventsList = this.state.InventoryEvents.list
        commit('SET_INVTYPE_EVENT_LIST', eventsList)
        return 'success'
      })
    },

    async GET_YEARS_LIST({ commit }) {
      return await handleApiCall({
        request: Api.load_years_dropdown_list(), 
        caller: 'GET_YEARS_LIST',
        commit,
        mutation: 'SET_YEARS_LIST'
      });
    },

    async GET_BUDGET_YEARS_LIST({ commit }, show_all = true) {
      return await handleApiCall({
        request: Api.load_works_years_dropdown_list(), 
        caller: 'GET_BUDGET_YEARS_LIST',
        commit,
        mutation: 'SET_BUDGET_YEARS_LIST'
      });
    },

    async GET_IMPORT_TYPES_DROPDOWN({ commit }) {
      return await handleApiCall({
        request: Api.load_import_types_dropdown_list(), 
        caller: 'GET_IMPORT_TYPES_DROPDOWN',
        commit,
        mutation: 'SET_IMPORT_TYPES_LIST'
      });
    },

    async GET_ROLES_DROPDOWN({ dispatch, commit }, show_all = true) {
      const res = filterAndSortList(
        await dispatch('LOAD_ROLES_LIST', true),
        { id: 'role_id', description: 'role_key' },  // fields for mapping
        {},  // filters
        show_all
      )

      commit('SET_ROLES', res)
      return 'success'
    },

    async GET_USERS_DROPDOWN({ dispatch, commit }, show_all = true) {
      const res = filterAndSortList(
        await dispatch('LOAD_ROLES_LIST', true),
        { id: 'role_id', description: 'role_key' },  // fields for mapping
        {},  // filters
        show_all
      )

      commit('SET_USERS', res)
      return 'success'
    },

  },

  mutations: {
    SET_NETWORK_LIST(state, list) {
      state.roadnetwork = [...list]
    },
    SET_REGION_LIST(state, list) {
      state.regions = [...list]
    },
    SET_DEU_LIST(state, list) {
      state.deus = [...list]
    },
    SET_ROAD_CLASSES_LIST(state, list) {
      state.road_classes = [...list]
    },
    SET_ROADS_LIST(state, list) {
      state.roads = [...list]
    },
    SET_SECTIONS_LIST(state, list) {
      state.sections = [...list]
    },
    SET_CATEGORIES_LIST(state, list) {
      state.work_categories = [...list];
    },
    SET_INVTYPE_EVENT_LIST(state, list) {
      state.invtype_events = [...list];
    },
    SET_YEARS_LIST(state, list) {
      state.years = [...list];
    },
    SET_BUDGET_YEARS_LIST(state, list) {
      state.budget_years = [...list];
    },
    SET_IMPORT_TYPES_LIST(state, list) {
      state.import_types = [...list];
    },
    SET_ROLES(state, list) {
      state.roles = [...list];
    },
    SET_USERS(state, list) {
      state.users = [...list];
    },

  },
}