/* 
File: condition_index_criteria.js
Description: called from ConditionIndexCriteria.vue, provides routines for conditon edit forms calling API's.
*/
import Api from '@/api/condition'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    list: []
  },
  actions: {
    async LOAD_CONDITION_INDEX_CRITERIA_LIST({ commit }) {
      return await handleApiCall({
        request: Api.load_condition_index_criteria_all(), 
        caller: 'LOAD_CONDITION_INDEX_CRITERIA_LIST',
        commit, mutation: 'SET_CONDITION_INDEX_CRITERIA_LIST'
      });
    },

    async LOAD_CONDITION_INDEX_CRITERIA_PIVOT({ commit }) {
      return await handleApiCall({
        request: Api.load_condition_index_criteria_pivot(), 
        caller: 'LOAD_CONDITION_INDEX_CRITERIA_PIVOT',
        commit, mutation: 'SET_CONDITION_INDEX_CRITERIA_LIST'
      });
    },

    async LOAD_CONDITION_INDEX_CRITERIA_BY_ID({ commit }, cond_id) {
      return await apiCall(Api.load_condition_index_criteria_by_id(cond_id), 'LOAD_CONDITION_INDEX_CRITERIA_BY_ID');
    },

    async ADD_CONDITION_INDEX_CRITERIA({ commit }, condition) {
      return await apiCall(Api.add_condition_index_criteria(condition), 'ADD_CONDITION_INDEX_CRITERIA', 201);
    },

    async UPD_CONDITION_INDEX_CRITERIA({ commit }, {id, condition}) {
      return await apiCall(Api.upd_condition_index_criteria(id, condition), 'UPD_CONDITION_INDEX_CRITERIA', 201);
    },

    async DEL_CONDITION_INDEX_CRITERIA({ commit }, cond_id) {
      return await apiCall(Api.del_condition_index_criteria(cond_id), 'DEL_CONDITION_INDEX_CRITERIA');
    },
  },

  mutations: {
    SET_CONDITION_INDEX_CRITERIA_LIST(state, list) {
      state.list = [...list]
    },
  },

  getters: {
    getDropDownList: (state) => {
      const res = state.list.map(item => ({ id: item.indicator_type, description: `condition.${item.indicator_type}` }))
      res.unshift({ id: '', description: '' })
      return res
    },

    getScores: (state) => {
      return [{ val: 0 }, { val: 1 }, { val: 2 }, { val: 3 }, { val: 4 }]
    },

    criteriaListFiltered: (state) => (type) => {
      return state.list.filter(item => !type || item.indicator_type === type)
    },
  }
}