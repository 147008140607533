/* 
File: inventory.js
Description: provides API calls for InventoryTypes.vue, InventoryTypeEditForm.vue, 
InventoryEvents.vue and InventoryEvenmtEditForm.vue
*/
import Api from '@/api/inventory'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    inventoryTypesList: []
  },

  actions: {
    /*---------  Inventory Types  ---------------------*/
    async LOAD_INVENTORY_TYPES_LIST({ commit }) {
      return await handleApiCall({
        request: Api.load_inventory_types_list(),
        caller: 'LOAD_INVENTORY_TYPES_LIST',
        commit, mutation: 'SET_INVENTORY_TYPES_LIST'
      });
    },

    async LOAD_INVENTORY_TYPE_BY_ID({ commit }, inventory_type_id) {
      return await apiCall(Api.load_rdb_inventory_type_by_id(inventory_type_id), 'LOAD_INVENTORY_TYPE_BY_ID');
    },

    async ADD_INVENTORY_TYPE({ commit }, newInventoryType) {
      return await apiCall(Api.add_rdb_inventory_type(newInventoryType), 'ADD_INVENTORY_TYPE', 201);
    },

    async UPD_INVENTORY_TYPE({ commit }, { id, invType }) {
      return await apiCall(Api.upd_rdb_inventory_type(id, invType), 'UPD_INVENTORY_TYPE', 201); //200);
    },

    /*---------  Inventory Events  ---------------------*/
    async LOAD_INVENTORY_EVENTS_LIST({ commit }) {
      return await handleApiCall({
        request: Api.load_inventory_events_list(),
        caller: 'LOAD_INVENTORY_EVENTS_LIST',
        commit, mutation: 'SET_INVENTORY_EVENTS_LIST'
      });
    },

    async LOAD_INVENTORY_EVENT_BY_ID({ commit }, inventory_event_id) {
      return await apiCall(Api.load_rdb_inventory_event_by_id(inventory_event_id), 'LOAD_INVENTORY_EVENT_BY_ID');
    },

    async ADD_INVENTORY_EVENT({ commit }, newInventoryEvent) {
      return await apiCall(Api.add_rdb_inventory_event(newInventoryEvent), 'ADD_INVENTORY_EVENT', 201);
    },

    async UPD_INVENTORY_EVENT({ commit }, { id, invEvent }) {
      return await apiCall(Api.upd_rdb_inventory_event(id, invEvent), 'UPD_INVENTORY_EVENT', 201); //200);
    },
  },

  mutations: {
    SET_INVENTORY_TYPES_LIST(state, list) {
      state.inventoryTypesList = [...list]
    },
    SET_INVENTORY_EVENTS_LIST(state, list) {
      state.inventoryEventsList = [...list]
    }
  },

  getters: {}
}