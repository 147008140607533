/* 
File: api/treatments.js
Description: API connections for the async functions for the Treatment Types and Rates and Treatment Matrix.
*/
import Api_ref from '@/api'

const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getLangQuery = () => {
  const lang = global.localStorage["mps-locale"] || "en";
  return `?lang=${lang}`;
};

export default {
  /*---------  Treatment Types  ---------------------*/
  load_treatment_types() {
    return Api.get(`/treatment_type/all${getLangQuery()}`, getHeaders());
  },

  load_treatment_type_by_id(id) {
    return Api.get(`/treatment_type/${id}`, getHeaders());
  },

  add_treatment_type(treatment_type) {
    return Api.post(`/treatment_type`, treatment_type, getHeaders());
  },

  upd_treatment_type(id, treatment_type) {
    return Api.put(`/treatment_type/${id}`, treatment_type, getHeaders());
  },

  /*---------  Rates  ---------------------*/
  load_treatment_rates() {
    return Api.get(`/treatment_rate/all${getLangQuery()}`, getHeaders());
  },

  load_treatment_rate_by_id(id) {
    return Api.get(`/treatment_rate/${id}`, getHeaders());
  },

  add_treatment_rate(treatmentRate) {
    return Api.post(`/treatment_rate`, treatmentRate, getHeaders());
  },

  /*---------  Treatment matrix ---------------------*/
  load_treatment_matrix() {
    return Api.get(`/treatment_matrix/all${getLangQuery()}`, getHeaders());
  },

  load_treatment_matrix_pivot_html() {
    return Api.get(`/treatment_matrix/pivot_html${getLangQuery()}`, getHeaders());
  },

  load_treatment_matrix_pivot_excel() {
    return Api.get(`/treatment_matrix/pivot_excel${getLangQuery()}`, getHeaders());
  },

  load_treatment_matrix_item(id) {
    return Api.get(`/treatment_matrix/${id}`, getHeaders());
  },

  add_treatment_matrix_item(item) {
    return Api.post('/treatment_matrix', item, getHeaders());
  },

  upd_treatment_matrix_item(id, item) {
    return Api.put(`/treatment_matrix/${id}`, item, getHeaders());
  },

  del_treatment_matrix_item(id) {
    return Api.post(`/treatment_matrix/${id}`, id, getHeaders());
  }
}