/* 
File: condition.js
Description: called from Condition.vue, provides routines for conditon edit forms calling API's.
*/
import Api from '@/api/condition'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    list: []
  },

  actions: {
    async LOAD_CONDITION_LIST({ commit }, section_id) {
      if (section_id == null || section_id == '') {
        commit('SET_CONDITION_LIST', [])
        return 'success'
      }
      return await handleApiCall({
        request: Api.load_condition_all({ section_id }),
        caller: 'LOAD_CONDITION_LIST',
        commit, mutation: 'SET_CONDITION_LIST'
      });
    },

    async LOAD_CONDITION_DATA_FOR_CHART({ commit }, payload) {
      if (payload?.section_id == null || payload?.section_id == '') {
        return []
      }
      return await apiCall(Api.load_condition_all(payload), 'LOAD_CONDITION_DATA_FOR_CHART');
    },

    async LOAD_CONDITION_BY_ID({ commit }, cond_id) {
      return await apiCall(Api.load_condition_by_id(cond_id), 'LOAD_CONDITION_BY_ID');
    },

    async ADD_CONDITION({ commit }, condition) {
      return await apiCall(Api.add_condition(condition), 'ADD_CONDITION', 201);
    },

    async UPD_CONDITION({ commit }, payload) {
      const { id, condition } = payload
      return await apiCall(Api.upd_condition(id, condition), 'UPD_CONDITION');
    },

    async DEL_CONDITION({ commit }, cond_id) {
      return await apiCall(Api.del_condition(cond_id), 'DEL_CONDITION');
    },

    async GET_CONDITION_DATA_BY_COORDS({ commit }, coords) {
      //const [ x, y ] = coords
      const x = Math.round(coords[0])
      const y = Math.round(coords[1])
      return await apiCall(Api.get_condition_data_by_coords(x, y), 'GET_CONDITION_DATA_BY_COORDS');
    },
  },

  mutations: {
    SET_CONDITION_LIST(state, list) {
      state.list = [...list]
    },
  },

  getters: {
    conditionListFiltered: (state) => (year) => {
      return state.list.filter(el => !year || el.survey_year === year);
    },

    yearsInConditionList: (state) => {
      // Get all unique years in the list, order and return as an  array of objects for BaseDropdown
      const uniqueYears = [...new Set(state.list.reduce((acc, item) => {
        if (item.survey_year !== null) acc.push(item.survey_year)
        return acc
      }, []))]
        .sort((a, b) => a - b)
        .map(year => ({ year }));

      uniqueYears.unshift({ year: '' })   // Add null value to the beginnig
      return uniqueYears
    },
  }
}
