/* 
File: refdata.js
Description: api's for reference data - carriageways, pavement types, etc.
*/
import Api from '@/api/reference_data'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

const LRPs = [
  { id: 1, description: 'Intersection', },
  { id: 2, description: 'Railway crossing', },
  { id: 3, description: 'Bridge', },
  { id: 4, description: 'Culvert', },
  { id: 5, description: 'Kilometre post', },
  { id: 6, description: 'Start / End', },
  { id: 7, description: 'Border' }
]

export default {
  state: {
    unitsList: [],
    pavement_types: [],
    surface_types: [],
    terrain_types: [],
    LandUseTypes: [],
    lprs: [],
    workAreas: []
  },

  actions: {
    /* ---  Units --- */
    async LOAD_UNIT_LIST({ commit }, show_all = false) {
      const res = await apiCall(Api.load_units(), 'LOAD_UNIT_LIST');

      if (show_all) res.unshift({ id: 0, description: '' })   // Add null value to the beginnig
      commit('SET_UNIT_LIST', res)
      return res
    },

    async LOAD_UNIT_BY_ID({ commit }, id) {
      return await apiCall(Api.load_unit_by_id(id), 'LOAD_UNIT_BY_ID');
    },

    async ADD_UNIT({ commit }, unit) {
      return await apiCall(Api.add_unit(unit), 'ADD_UNIT', 201);
    },

    async UPD_UNIT({ commit }, { id, theUnit }) {
      return await apiCall(Api.upd_unit(id, theUnit), 'UPD_UNIT', 201);
    },

    /* ---  RDB Lookups --- */
    async LOAD_RDB_LOOKUP({ commit }, lookup_type) {
      const res = await apiCall(Api.load_rdb_lookup(lookup_type), 'LOAD_RDB_LOOKUP')
      return res.map(item => ({
        id: item.lookup_key,
        description: item.description
      }));
    },

    async LOAD_PAVEMENT_TYPES({ commit, dispatch }) {
      const res = await dispatch('LOAD_RDB_LOOKUP', 'pavement_type')
      commit('SET_PAVEMENT_TYPES', res)
    },

    async LOAD_SURFACE_TYPES({ commit, dispatch }) {
      const res = await dispatch('LOAD_RDB_LOOKUP', 'surface_type')
      commit('SET_SURFACE_TYPES', res)
    },

    async LOAD_TERRAIN_TYPES({ commit, dispatch }) {
      const res = await dispatch('LOAD_RDB_LOOKUP', 'terrain_type')
      commit('SET_TERRAIN_TYPES', res)
    },

    async LOAD_LAND_USE({ commit, dispatch }) {
      const res = await dispatch('LOAD_RDB_LOOKUP', 'land_use')
      commit('SET_LAND_USE', res)
    },

    async LOAD_LRPS({ commit, dispatch }) {
      //const res = await dispatch('LOAD_RDB_LOOKUP', 'lrp')
      //commit('SET_LRPS', res)
      commit('SET_LRPS', LRPs)
    },

    async LOAD_WORK_AREAS({ commit, dispatch }) {
      const res = await dispatch('LOAD_RDB_LOOKUP', 'work_area')
      commit('SET_WORK_AREAS', res)
    },
  },

  mutations: {
    SET_UNIT_LIST(state, list) {
      state.unitsList = [...list]
    },
    SET_PAVEMENT_TYPES(state, list) {
      state.pavement_types = [...list]
    },
    SET_SURFACE_TYPES(state, list) {
      state.surface_types = [...list]
    },
    SET_TERRAIN_TYPES(state, list) {
      state.terrain_types = [...list]
    },
    SET_LAND_USE(state, list) {
      state.LandUseTypes = [...list]
    },
    SET_LRPS(state, list) {
      state.lrps = [...list]
    },
    SET_WORK_AREAS(state, list) {
      state.workAreas = [...list]
    },
  },

  getters: {
  }
}