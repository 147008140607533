/* 
File: import.js
Description: called API for importing data.
*/
import Api from '@/api/import'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    import_status: [],
    import_log: [],
  },

  actions: {
    async GET_ROAD_IMPORT_TEMPLATE({ commit }) {
      return await apiCall(Api.get_rdb_road_import_template(), 'GET_ROAD_IMPORT_TEMPLATE');
    },

    async GET_SECTION_IMPORT_TEMPLATE({ commit }) {
      return await apiCall(Api.get_rdb_section_import_template(), 'GET_SECTION_IMPORT_TEMPLATE');
    },

    async IRI_IMPORT_TEMPLATE({ commit }) {
      return await apiCall(Api.get_rdb_iri_import_template(), 'IRI_IMPORT_TEMPLATE');
    },

    async RUTTING_IMPORT_TEMPLATE({ commit }) {
      return await apiCall(Api.get_rdb_rutting_import_template(), 'IRI_IMPORT_TEMPLATE');
    },

    async RUN_ROAD_IMPORT({ commit }, import_file) {
      return await apiCall(Api.import_rdb_road(import_file), 'RUN_ROAD_IMPORT', 201);
    },

    async RUN_SECTION_IMPORT({ commit }, import_file) {
      return await apiCall(Api.import_rdb_section(import_file), 'RUN_SECTION_IMPORT', 201);
    },

    async DATA100_IMPORT({ commit }, values) {
      const { import_files, section_id } = values
      return await apiCall(Api.import_rdb_data100(import_files, section_id), 'DATA100_IMPORT', 201);
    },

    async IMPORT_LOG_ALL({ commit }, batch_id) {
      return await handleApiCall({
        request: Api.get_import_log_all(batch_id), 
        caller: 'IMPORT_LOG_ALL',
        commit, mutation: 'SET_IMPORT_STATUS'
      });
    },

    async IMPORT_LOG_BY_ID({ commit }, log_id) {
      return await apiCall(Api.get_import_log_by_id(log_id), 'IMPORT_LOG_BY_ID');
    }
  },

  mutations: {
    SET_IMPORT_STATUS(state, list) {
      state.import_status = [...list]
    },
    SET_IMPORT_LOG(state, list) {
      state.import_log = [...list]
    },
  },
  getters: {}
}