/* 
File: planning.js
Description: API connections for the async functions for old planning functionality.
*/
import Api_ref from '@/api'

const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getLangQuery = () => {
  const lang = global.localStorage["mps-locale"] || "en";
  return `?lang=${lang}`;
};

export default {
  /*---------  PLANS  ---------------------*/
  load_plan_list(region_id, year) {
    let apiStr = `/plan/all${getLangQuery()}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (year) apiStr += `&year=${year}`

    return Api.get(apiStr, getHeaders());
  },

  load_plan_by_id(id) {
    return Api.get(`/plan/${id}`, getHeaders());
  },

  add_plan(payload) {
    return Api.post(`/plan`, payload, getHeaders());
  },

  upd_plan(id, plan) {
    return Api.put(`/plan/${id}`, plan, getHeaders());
  },

  approve_plan(id){
    return Api.put(`/plan/approve/${id}`, getHeaders());
  },

  cancel_plan(id){ 
    return Api.put(`/plan/cancel_approval/${id}`, getHeaders());
  },

  delete_plan(id){ 
    return Api.delete(`/plan/${id}`, getHeaders());
  },

  /*  ---------  PLANNING  ---------------------*/
  /* TODO: Review and remove if not needed also from the APIs */
  load_plan_current() {
    return Api.get(`/planning/plans/current`, getHeaders());
  },

  plan_change_lock_status(id) {
    return Api.put(`/planning/plans/${id}/lock`, getHeaders());
  },

  load_av_works_list(id = null) {
    return Api.get(      `/planning/plans/${id}/available_works`,      { plan_id: id },      getHeaders());
  },

  load_av_work_info(id) {
    return Api.get(`/planning/works/${id}`, getHeaders());
  },

  load_plans_list() {
    return Api.get(`/planning/plans`, getHeaders());
  },

  load_plan_info(id) {
    return Api.get(`/planning/plans/${id}`, getHeaders());
  },

  load_plan_works(id) {
    return Api.get(`/planning/plans/${id}/works`, getHeaders());
  },

  add_plan_works(id, works) {
    return Api.post(      `/planning/plans/${id}/works`,      { work_ids: works },getHeaders());
  },

  load_plan_work_info(plan_id, work_id) {
    return Api.get(`/planning/plans/${plan_id}/works/${work_id}`, getHeaders());
  },

  plan_works_approval(id, works) {
    return Api.put(      `/planning/plans/${id}/works/approval`,      { work_ids: works },getHeaders());
  },

  plan_export(plan_id, body) {
    return Api.post(`/planning/plans/${plan_id}/export`, { ...body }, {
      responseType: 'blob',
      timeout: 30000,
      headers: {
        Accept: 'application/vnd.ms-excel',
        ...type_json
      }
    })
  },

  /*  ---------  DASHBOARD  ---------------------*/
  /** Charts data
   * @return {Promise<*> {}} - 200 Response
   * @throws Error
   */
  load_dashboard_charts_data(plan_id) {
    return Api.get(`/planning/plans/${plan_id}/stats`, getHeaders());
  },
}