/* 
File: workcategories.js
Description: called from WorkCategories.vue and WorkCategoryEditForm.vue, 
gets Work Categories data from DB and passes as a list back to the caller.
*/
import Api from '@/api/reference_data'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    list: []
  },

  actions: {
    async LOAD_WORK_CATEGORIES_LIST({ commit }, forDropdown = false) {
      return await handleApiCall({
        request: Api.load_work_categories(),
        caller: 'LOAD_WORK_CATEGORIES_LIST',
        commit: !forDropdown ? commit : null,
        mutation: !forDropdown ? 'SET_WORK_CATEGORIES_LIST' : null
      });
    },

    async LOAD_WORK_CATEGORY_BY_ID({ commit }, work_category_id) {
      return await apiCall(Api.load_work_category_by_id(work_category_id), 'LOAD_WORK_CATEGORY_BY_ID');
    },

    async ADD_WORK_CATEGORY({ commit }, work_category) {
      return await apiCall(Api.add_work_category(work_category), 'ADD_WORK_CATEGORY', 201);
    },

    async UPD_WORK_CATEGORY({ commit }, { id, work_category }) {
      return await apiCall(Api.upd_work_category(id, work_category), 'UPD_WORK_CATEGORY', 201);   //200);
    },
  },

  mutations: {
    SET_WORK_CATEGORIES_LIST(state, list) {
      state.list = [...list]
    }
  },

  getters: {}
}