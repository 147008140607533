/* 
File: roadnetwork.js
Description: implements API calls for the Road Network parameters (Regions, DEU, Roads, etc.)
*/
import Api from '@/api/roadnetwork'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    regionsList: [],
    deuList: [],
    roadsList: []
  },

  actions: {
    /*---------  REGIONS  ---------------------*/
    async LOAD_REGIONS_LIST({ commit }, forDropdown = false) {
      return await handleApiCall({
        request: Api.load_rdb_regions_list(),
        caller: 'LOAD_REGIONS_LIST',
        commit: !forDropdown ? commit : null,
        mutation: !forDropdown ? 'SET_RDB_REGIONS_LIST' : null
      });
    },

    async LOAD_RDB_REGION_BY_ID({ commit }, rid) {
      return await apiCall(Api.load_rdb_region_by_id(rid), 'LOAD_RDB_REGION_BY_ID');
    },

    async ADD_RDB_REGION({ commit }, region) {
      return await apiCall(Api.add_rdb_region(region), 'ADD_RDB_REGION', 201);
    },

    async UPD_RDB_REGION({ commit }, { id, theRegion }) {
      return await apiCall(Api.upd_rdb_region(id, theRegion), 'UPD_RDB_REGION');
    },

    async DEL_RDB_REGION({ commit }, region_id) {
      return await apiCall(Api.del_rdb_region(region_id), 'DEL_RDB_REGION');
    },

    /*---------  DEU  ---------------------*/
    async LOAD_DEU_LIST({ commit }, params) {
      const { region_id, forDropdown = false } = params
      return await handleApiCall({
        request: Api.load_rdb_deu_list(region_id),
        caller: 'LOAD_DEU_LIST',
        commit: !forDropdown ? commit : null,
        mutation: !forDropdown ? 'SET_DEU_LIST' : null
      });
    },

    async LOAD_DEU_BY_ID({ commit }, deu_id) {
      return await apiCall(Api.load_rdb_deu_by_id(deu_id), 'LOAD_DEU_BY_ID');
    },

    async ADD_DEU({ commit }, newDeu) {
      return await apiCall(Api.add_rdb_deu(newDeu), 'ADD_DEU', 201);
    },

    async UPD_DEU({ commit }, payload) {
      const { id, theDeu } = payload
      return await apiCall(Api.upd_rdb_deu(id, theDeu), 'UPD_DEU');
    },

    async DEL_DEU({ commit }, deu_id) {
      return await apiCall(Api.del_rdb_deu(deu_id), 'DEL_DEU');
    },

  /*---------  ROADS  ---------------------*/
    async LOAD_ROADS_LIST({ commit }, road_class) {
      return await handleApiCall({
        request: Api.load_rdb_roads_list(road_class),
        caller: 'LOAD_ROADS_LIST',
        commit, mutation: 'SET_ROADS_LIST'
      });
    },

    async LOAD_ROAD_BY_ID({ commit }, rid) {
      return await apiCall(Api.load_rdb_road_by_id(rid), 'LOAD_ROAD_BY_ID');
    },

    async ADD_ROAD({ commit }, newRoad) {
      return await apiCall(Api.add_rdb_road(newRoad), 'ADD_ROAD', 201);
    },

    async UPD_ROAD({ commit }, { id, theRoad }) {
      return await apiCall(Api.upd_rdb_road(id, theRoad), 'UPD_ROAD');
    },

    async DEL_ROAD({ commit }, road_id) {
      return await apiCall(Api.del_rdb_road(road_id), 'DEL_ROAD');
    }
  },

  mutations: {
    SET_RDB_REGIONS_LIST(state, list) {
      state.regionsList = [...list]
    },
    SET_DEU_LIST(state, list) {
      state.deuList = [...list]
    },
    SET_ROADS_LIST(state, list) {
      state.roadsList = [...list]
    },
  },

  getters: {
    roadsByClass: (state) => (road_class) => {
      return state.roadsList.filter(el => !road_class || el.road_class === road_class)  // Проверка если передан параметр
    },
  }
}