/*
File: api/budget.js
Description: API connections for the async functions for Budget and Works.
*/
import Api_ref from '@/api'

const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getLangQuery = () => {
  const lang = global.localStorage["mps-locale"] || "en";
  return `?lang=${lang}`;
};

export default {
  /*--------- Budget  ---------------------*/
  load_budget() {
    return Api.get(`/rdb/budget/all${getLangQuery()}`, getHeaders());
  },

  load_budget_by_id(id) {
    return Api.get(`/rdb/budget/${id}${getLangQuery()}`, getHeaders());
  },
  
  add_budget(budget) {
    return Api.post(`/rdb/budget`, budget, getHeaders());
  },

  upd_budget(id, budget) {
    return Api.put(`/rdb/budget/${id}`, budget, getHeaders());

  },
  del_budget(budget_id) {
    return Api.delete(`/rdb/budget/${budget_id}`, getHeaders());
  },

  assign_wp_budget(item) { //item: {budget_id, [id1, id2 ...]}
    //    const { budget_id, section_maintenance_plan_id } = item
    //    console.log(budget_id, section_maintenance_plan_id)
    item.budget_id = Number(item.budget_id)
    return Api.put("/rdb/section_maintenance_plan/assign_budget", item,getHeaders());
  },

  approve_budget(id){
    return Api.put(`/rdb/budget/approve/${id}`, getHeaders());
  },

  cancel_budget_approval(id){ 
    return Api.put(`/rdb/budget/cancel_approval/${id}`, getHeaders());
  },

  /*--------- Works  ---------------------*/
  load_works_all() {
    return Api.get(`/rdb/section_maintenance_plan/all${getLangQuery()}`, getHeaders());
  },

  load_works(region_id, year) {
    return Api.get(`/rdb/section_maintenance_plan/report_budget_assigned?year=${year}&region_id=${region_id}`, getHeaders());
  },
}