/* 
File: treatments.js
Description: API calls for the screens with Treatment Types, Treatment Rates and Treatment Matrix.
*/
import Api from '@/api/treatments'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

// Move to the dropdowns
const thresholdVariablesList = [
  { key: "rutting", description_en: 'Rutting', description_ru: 'Колейность', description_kg: 'Колейность' },
  { key: "iri", description_en: 'IRI', description_ru: 'Ровность', description_kg: 'Ровность' },
  { key: "aadt", description_en: 'AADT', description_ru: 'Интенсивность', description_kg: 'Интенсивность' },
  { key: "cracking", description_en: 'Cracking', description_ru: 'Трещины', description_kg: 'Трещины' },
  { key: "potholes", description_en: 'Potholes', description_ru: 'Ямы', description_kg: 'Ямы' },
]

export default {
  state: {
    treatmentTypesList: [],
    treatmentRatesList: [],
    treatmentMatrix: [],
    treatmentMatrixPivot: null,
    variablesList: [],
  },

  actions: {
    /*---------  Treatment Types  ---------------------*/
    async LOAD_TREATMENT_TYPES_LIST({ commit }, showAll = false) {
      // TODO change also in the dependent components
      /*  return await handleApiCall({
          request: Api.load_treatment_types(), 
          caller: 'LOAD_TREATMENT_TYPES_LIST',
          commit: !forDropdown ? commit : null,
          mutation: !forDropdown ? 'SET_TREATMENT_TYPES_LIST' : null
        });
        */
      const res = await apiCall(Api.load_treatment_types(), 'LOAD_TREATMENT_TYPES_LIST');
      if (showAll) res.unshift({ treatment_type_id: 0, key: '', treatment_type_description: '' })
      //res.unshift({ id: 0, key: '', description: '' })
      commit('SET_TREATMENT_TYPES_LIST', res)
      return 'success'
    },

    async LOAD_TREATMENT_TYPE_BY_ID({ commit }, id) {
      return await apiCall(Api.load_treatment_type_by_id(id), 'LOAD_TREATMENT_TYPE_BY_ID');
    },

    async ADD_TREATMENT_TYPE({ commit }, treatment_type) {
      return await apiCall(Api.add_treatment_type(treatment_type), 'ADD_TREATMENT_TYPE', 201);
    },

    async UPD_TREATMENT_TYPE({ commit }, { id, treatment_type }) {
      return await apiCall(Api.upd_treatment_type(id, treatment_type), 'UPD_TREATMENT_TYPE', 201);
    },

    /*--------- Threshold Variables  ---------------------*/
    async GET_THRESHOLD_VARIABLES_DROPDOWN({ commit }, showAll = false) {
      try {
        // TODO: change to map()
        const res = []
        thresholdVariablesList.forEach((element) => {
          res.push({ key: element.key, description: element[`description_${lang}`] })
        })
        if (showAll) res.res.unshift({ key: '', description: '' })
        commit('SET_VARIABLES_LIST', res)
        return 'success'
      } catch ({ response }) {
        throw new Error(response?.data?.msg || `Failed: GET_THRESHOLD_VARIABLES_DROPDOWN`);
      }
    },

    /*--------- Treatment Rates  ---------------------*/
    async LOAD_TREATMENT_RATE_LIST({ commit }) {
      return await handleApiCall({
        request: Api.load_treatment_rates(),
        caller: 'LOAD_TREATMENT_RATE_LIST',
        commit, mutation: 'SET_TREATMENT_RATE_LIST'
      });
    },

    async LOAD_TREATMENT_RATE_BY_ID({ commit }, id) {
      return await apiCall(Api.load_treatment_rate_by_id(id), 'LOAD_TREATMENT_RATE_BY_ID');
    },

    async ADD_TREATMENT_RATE({ commit }, treatmentRate) {
      return await apiCall(Api.add_treatment_rate(treatmentRate), 'ADD_TREATMENT_RATE', 201);
    },

    async UPD_TREATMENT_RATE({ commit }, { id, treatmentRate }) {
      return await apiCall(Api.upd_treatment_rate(id, treatmentRate), 'UPD_TREATMENT_RATE');
    },

    /*---------  Treatment matrix ---------------------*/
    async LOAD_TREATMENT_MATRIX({ commit }) {
      return await handleApiCall({
        request: Api.load_treatment_matrix(),
        caller: 'LOAD_TREATMENT_MATRIX',
        commit, mutation: 'SET_TREATMENT_MATRIX'
      });
    },

    async LOAD_TREATMENT_MATRIX_PIVOT_HTML({ commit }) {
      return await handleApiCall({
        request: Api.load_treatment_matrix_pivot_html(),
        caller: 'LOAD_TREATMENT_MATRIX_PIVOT_HTML',
        commit, mutation: 'SET_TREATMENT_MATRIX_PIVOT'
      });
    },

    async LOAD_TREATMENT_MATRIX_PIVOT_EXCEL({ commit }, id) {
      return await apiCall(Api.load_treatment_matrix_pivot_excel(), 'LOAD_TREATMENT_MATRIX_PIVOT_EXCEL');
    },

    async LOAD_TREATMENT_MATRIX_ITEM({ commit }, id) {
      return await apiCall(Api.load_treatment_matrix_item(id), 'LOAD_TREATMENT_MATRIX_ITEM');
    },

    async ADD_TREATMENT_MATRIX_ITEM({ commit }, item) {
      return await apiCall(Api.add_treatment_matrix_item(item), 'ADD_TREATMENT_MATRIX_ITEM', 201);
    },

    async UPD_TREATMENT_MATRIX({ commit }, { id, item }) {
      return await apiCall(Api.upd_treatment_matrix_item(id, item), 'UPD_TREATMENT_MATRIX', 201);
    },

    async DEL_TREATMENT_MATRIX({ commit }, id) {
      return await apiCall(Api.del_treatment_matrix_item(id), 'DEL_TREATMENT_MATRIX');
    }
  },

  mutations: {
    SET_TREATMENT_TYPES_LIST(state, list) {
      state.treatmentTypesList = [...list]
    },
    SET_VARIABLES_LIST(state, list) {
      state.variablesList = [...list]
    },
    SET_TREATMENT_RATE_LIST(state, list) {
      state.treatmentRatesList = [...list]
    },
    SET_TREATMENT_MATRIX(state, list) {
      state.treatmentMatrix = [...list]
    },
    SET_TREATMENT_MATRIX_PIVOT(state, list) {
      state.treatmentMatrixPivot = list
    },
  },

  getters: {
    treatmentTypesFiltered: (state) => (unit, category) => {
      return state.treatmentTypesList.filter(el =>
        (!unit || el.fk_unit === unit) &&    // фильтруем если задан
        (!category || el.fk_work_category === category)
      )
    }
  }
}