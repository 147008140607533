/*
File: api/roadnetwork.js
Description: API connections for the async functions for Regions, DEUs and Roads.
*/
import Api_ref from '@/api'
const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getLangQuery = () => {
  const lang = global.localStorage["mps-locale"] || "en";
  return `?lang=${lang}`;
};

export default {
  /*---------  REGIONS  ---------------------*/
  load_rdb_regions_list() {
    return Api.get(`/rdb/region/all${getLangQuery()}`, getHeaders());
  },

  load_rdb_region_by_id(id) {
    return Api.get(`/rdb/region/${id}`, getHeaders());
  },

  add_rdb_region(region) {
    return Api.post(`/rdb/region`, region, getHeaders());
  },

  upd_rdb_region(id, region) {
    return Api.put(`/rdb/region/${id}`, region, getHeaders());
  },

  del_rdb_region(region_id) {
    return Api.delete(`/rdb/region/${region_id}`, getHeaders());
  },

  /*---------  DEU  ---------------------*/
  load_rdb_deu_list(region_id) {
    let apiStr = `/rdb/deu/all${getLangQuery()}`
    if (region_id) apiStr += `&region_id=${region_id}`

    return Api.get(apiStr, getHeaders());
  },

  load_rdb_deu_by_id(deu_id) {
    return Api.get(`/rdb/deu/${deu_id}`, getHeaders());
  },

  add_rdb_deu(deu) {
    return Api.post(`/rdb/deu`, deu, getHeaders());
  },

  upd_rdb_deu(deu_id, deu) {
    return Api.put(`/rdb/deu/${deu_id}`, deu, getHeaders());
  },

  del_rdb_deu(deu_id) {
    return Api.delete(`/rdb/deu/${deu_id}`, getHeaders());
  },

  /*---------  ROADS  ---------------------*/
  load_rdb_roads_list(road_class) {
    let apiStr = `/rdb/road/all${getLangQuery()}`
    if (road_class) apiStr += `&road_class=${road_class}`

    return Api.get(apiStr, getHeaders());
  },

  load_rdb_road_by_id(road_id) {
    return Api.get(`/rdb/road/${road_id}`, getHeaders());
  },

  add_rdb_road(road) {
    return Api.post(`/rdb/road`, road, getHeaders());
  },

  upd_rdb_road(road_id, road) {
    return Api.put(`/rdb/road/${road_id}`, road, getHeaders());
  },

  del_rdb_road(road_id) {
    return Api.delete(`/rdb/road/${road_id}`, getHeaders());
  },
}