/*
File: api/users_groups.js
Description: API connections for the async functions for Users and Groups Mgmt.
*/
import Api_ref from '@/api'

const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

export default {
  /*---------  GROUPS  ---------------------*/
  load_group_list() {
    return Api.get(`/groups`, getHeaders());
  },

  load_group_by_id(id) {
    return Api.get(`/groups/${id}`, getHeaders());
  },

  load_region_list() {
    return Api.get(`/groups/regions`, getHeaders());
  },

  add_group(group) {
    return Api.post(`/groups`, group, getHeaders());
  },

  upd_group(id, group) {
    return Api.put(`/groups/${id}`, group, getHeaders());
  },

  del_group(id) {
    return Api.delete(`/groups/${id}`, getHeaders());
  },

  /*---------  USERS  ---------------------*/
  load_users_list() {
    return Api.get(`/users`, getHeaders());
  },

  load_users_groups() {
    return Api.get(`/groups`, getHeaders());
  },

  load_user_by_id(id) {
    return Api.get(`/users/${id}`, getHeaders());
  },

  add_user(user) {
    return Api.post(`/users`, { ...user }, getHeaders());
  },

  upd_user(id, user) {
    return Api.put(`/users/${id}`, { ...user }, getHeaders());
  },

  del_user(id) {
    return Api.delete(`/users/${id}`, getHeaders());
  },
}