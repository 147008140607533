import Api from '@/api/planning'
import { apiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    list: [],
    dropdownList: []
  },

  actions: {
    async LOAD_PLAN_LIST({ commit }, payload) {
      const { region_id, year, forDropdown = false } = payload
      const data = await apiCall(Api.load_plan_list(region_id, year), 'LOAD_PLAN_LIST');

      data.sort((a, b) => {
        let res = a.region_description.localeCompare(b.region_description, undefined, { sensitivity: 'base' })
        if (res == 0) { res = a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }) }
        return res
      })
      commit(forDropdown ? 'SET_PLAN_DROPDOWN_LIST' : 'SET_PLAN_LIST', data)
    },

    async LOAD_PLAN_BY_ID({ commit }, id) {
      return await apiCall(Api.load_plan_by_id(id), 'LOAD_PLAN_BY_ID');
    },

    async ADD_PLAN({ commit }, payload) {
      return await apiCall(Api.add_plan(payload), 'ADD_PLAN', 201);
    },

    async UPD_PLAN({ commit }, { id, plan }) {
      return await apiCall(Api.upd_plan(id, plan), 'UPD_PLAN', 201);
    },

    async APPROVE_PLAN({ commit }, id) {   //payload) {
      return await apiCall(Api.approve_plan(id), 'APPROVE_PLAN', 201);
    },

    async CANCEL_PLAN({ commit }, id) {
      return await apiCall(Api.cancel_plan(id), 'CANCEL_PLAN', 201);
    },

    async DELETE_PLAN({ commit }, id) {
      return await apiCall(Api.delete_plan(id), 'DELETE_PLAN');
    },

    async PLAN_CHANGE_LOCK_STATUS({ commit }, id) {
      return await apiCall(Api.plan_change_lock_status(id), 'PLAN_CHANGE_LOCK_STATUS');
      // return Boolean(res.data.locked_at)
    }
  },

  mutations: {
    SET_PLAN_LIST(state, list) {
      state.list = [...list]
    },
    SET_PLAN_DROPDOWN_LIST(state, list) {
      state.dropdownList = [...list]
    },
  },

  getters: {
    plansApproved: (state) => (fk_region, year) => {
      return state.dropdownList.filter(el =>
        el.approved_by_user_id &&            // утверждено пользователем
        (!fk_region || el.fk_region === fk_region) &&  // Проверка на регион (если передан)
        (!year || el.year === year)           // Проверка на год (если передан)
      )
        // Оставляем только нужные поля
        .map(el => ({ id: el.id, name: el.name }))
        // Сортировка по названию
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
    },

    plansNotApproved: (state) => (fk_region, year) => {
      return state.dropdownList.filter(el =>
        !el.approved_by_user_id &&            // Не утверждено пользователем
        (!fk_region || el.fk_region === fk_region) &&  // Проверка на регион (если передан)
        (!year || el.year === year)           // Проверка на год (если передан)
      )
        // Оставляем только нужные поля
        .map(el => ({ id: el.id, name: el.name }))
        // Сортировка по названию
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }))
    },

    planApproved: (state) => (id) => {
      return !id ? false : Boolean(state.list.find((el) => el.id == id)?.approved_by_user_id)
    },

    yearsInPlanList: (state) => {
      // Get all unique years in the list, order and return as an  array of objects for BaseDropdown
      const uniqueYears = [...new Set(state.list.reduce((acc, item) => {
        if (item.year !== null) acc.push(item.year)
        return acc
      }, []))].sort((a, b) => a - b).map(year => ({ year }));

      uniqueYears.unshift({ year: '' })   // Add null value to the beginnig
      return uniqueYears
    },

    planListByYear: (state) => (year) => {
      return !year ? state.list : state.list.filter(row => row.year === year)
    },
  }
}