/* 
File: api/reference_data.js
Description: API connections for the async functions for Units and Work Categories.
*/
import Api_ref from '@/api'

const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getLangQuery = () => {
  const lang = global.localStorage["mps-locale"] || "en";
  return `?lang=${lang}`;
};

export default {
  load_rdb_lookup(lookup_type) {
    return Api.get(`/rdb_lookup/all${getLangQuery()}&lookup_type=${lookup_type}`, getHeaders());
  },

  /*---------  Units  ---------------------*/
  load_units() {
    return Api.get(`/unit/all${getLangQuery()}`, getHeaders());
  },

  load_unit_by_id(id) {
    return Api.get(`/unit/${id}`, getHeaders());
  },

  add_unit(unit) {
    return Api.post(`/unit`, unit, getHeaders());
  },

  upd_unit(id, unit) {
    return Api.put(`/unit/${id}`, unit, getHeaders());
  },

  /*--------- Work Categories  ---------------------*/
  load_work_categories() {
    return Api.get(`/rdb/work_category/all${getLangQuery()}`, getHeaders());
  },

  load_work_category_by_id(id) {
    return Api.get(`/rdb/work_category/${id}${getLangQuery()}`, getHeaders());
  },

  add_work_category(work_category) {
    return Api.post(`/rdb/work_category`, work_category, getHeaders());
  },

  upd_work_category(id, work_category) {
    return Api.put(`/rdb/work_category/${id}`, work_category, getHeaders());
  },
}