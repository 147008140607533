/* 
File: reports.js
Description: API connections for the async functions for Reports .
*/
import Api_ref from '@/api'
import elevation_zones from '../store/jsons/elevation_zones.json'
import length_per_elevation from '../store/jsons/length_per_elevation.json'

const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getLangQuery = () => {
  const lang = global.localStorage["mps-locale"] || "en";
  return `?lang=${lang}`;
};

export default {
  report_1_administrative_setup(inserted_date, region_id) {
    let apiStr = `/rdb/reports/report_1_administrative_setup${getLangQuery()}`
    if (inserted_date) apiStr += `&inserted_date=${inserted_date}`
    if (region_id) apiStr += `&region_id=${region_id}`
    
    return Api.get(apiStr, getHeaders());
  },

  report_2_roads_info(inserted_date, region_id, deu_id, road_class) {
    let apiStr = `/rdb/reports/report_2_roads${getLangQuery()}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (deu_id) apiStr += `&deu_id=${deu_id}`
    if (road_class) apiStr += `&road_class=${road_class}`
    if (inserted_date) apiStr += `&inserted_date=${inserted_date}`
    
    return Api.get(apiStr, getHeaders());
  },

  report_3_year_wise_road_length(start_year, end_year, region_id, road_class, deu_id) {
    let apiStr = `/rdb/reports/report_3_year_wise_road_length${getLangQuery()}`
    if (start_year) apiStr += `&start_year=${start_year}`
    if (end_year) apiStr += `&end_year=${end_year}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (road_class) apiStr += `&road_class=${road_class}`
    if (deu_id) apiStr += `&deu_id=${deu_id}`

    return Api.get(apiStr, getHeaders());
  },

  report_4_road_length_by_traffic(start_year, end_year, region_id, road_class, deu_id) {
    let apiStr = `/rdb/reports/report_4_road_length_by_traffic_intensity${getLangQuery()}`
    if (start_year) apiStr += `&survey_year_from=${start_year}`
    if (end_year) apiStr += `&survey_year_to=${end_year}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (road_class) apiStr += `&road_class=${road_class}`
    if (deu_id) apiStr += `&deu_id=${deu_id}`

    return Api.get(apiStr, getHeaders());
  },

  report_5_road_way_details(region_id, road_class, deu_id, before_date) {
    let apiStr = `/rdb/reports/report_5_road_way_details${getLangQuery()}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (road_class) apiStr += `&road_class=${road_class}`
    if (deu_id) apiStr += `&deu_id=${deu_id}`
    if (before_date) apiStr += `&inserted_date=${before_date}`

    return Api.get(apiStr, getHeaders());
  },

  report_6_sections(region_id, road_class, deu_id, before_date) {
    let apiStr = `/rdb/reports/report_6_sections${getLangQuery()}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (road_class) apiStr += `&road_class=${road_class}`
    if (deu_id) apiStr += `&deu_id=${deu_id}`
    if (before_date) apiStr += `&inserted_date=${before_date}`

    return Api.get(apiStr, getHeaders());
  },

  report_7_road_condition_detailed(region_id, deu_id, road_id, start_date, end_date) {
    let apiStr = `/rdb/reports/report_7_road_condition_data_detailed${getLangQuery()}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (deu_id) apiStr += `&deu_id=${deu_id}`
    if (road_id) apiStr += `&road_id=${road_id}`
    if (start_date) apiStr += `&survey_date_from=${start_year}`
    if (end_date) apiStr += `&survey_date_from=${end_year}`

    return Api.get(apiStr, getHeaders());
  },

  report_7_road_condition_summary(region_id, deu_id, road_id, section_id, start_date, end_date) {
    let apiStr = `/rdb/reports/report_7_road_condition_data_summary${getLangQuery()}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (deu_id) apiStr += `&deu_id=${deu_id}`
    if (road_id) apiStr += `&road_id=${road_id}`
    if (section_id) apiStr += `&section_id=${section_id}`
    if (start_date) apiStr += `&survey_date_from=${start_date}`
    if (end_date) apiStr += `&survey_date_to=${end_date}`

    return Api.get(apiStr, getHeaders());
  },

  report_8_region_wise_condition(region_id, survey_year) {
    let apiStr = `/rdb/reports/report_8_region_wise_condition_distribution${getLangQuery()}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (survey_year) apiStr += `&survey_year=${survey_year}`

    return Api.get(apiStr, getHeaders());
  },

  report_9_yearly_condition(region_id, deu_id, road_id, section_id, start_year, end_year) {
    let apiStr = `/rdb/reports/report_9_yearly_condition_index${getLangQuery()}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (deu_id) apiStr += `&deu_id=${deu_id}`
    if (road_id) apiStr += `&road_id=${road_id}`
    if (section_id) apiStr += `&section_id=${section_id}`
    if (start_year) apiStr += `&survey_year_from=${start_year}`
    if (end_year) apiStr += `&survey_year_to=${end_year}`

    return Api.get(apiStr, getHeaders());
  },

  report_10_section_wise_aadt(start_year, end_year, region_id, deu_id, road_id) {
    let apiStr = `/rdb/reports/report_10_section_wise_aadt${getLangQuery()}`
    if (start_year) apiStr += `&survey_year_from=${start_year}`
    if (end_year) apiStr += `&survey_year_to=${end_year}`
    if (region_id) apiStr += `&region_id=${region_id}`
    if (deu_id) apiStr += `&deu_id=${deu_id}`
    if (road_id) apiStr += `&road_id=${road_id}`

    return Api.get(apiStr, getHeaders());
  },

  report_11_total_elevation_zones() {
    const res = { status: 200, data: elevation_zones }
    return res
  },

  report_12_road_length_by_elevation_zones() {
    const res = { status: 200, data: length_per_elevation }
    return res
  },
}