/* 
File: budget.js
Description: called from Budgets.vue, returns budget data for each version: 1, year, region and category.
*/
import Api from '@/api/budget'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

export default {
  state: {
    list: []
  },

  actions: {
    async LOAD_BUDGET_LIST({ commit }) {
      return await handleApiCall({
        request: Api.load_budget(),
        caller: 'LOAD_BUDGET_LIST',
        commit, mutation: 'SET_BUDGET_LIST'
      });
    },

    async LOAD_BUDGET_BY_ID({ commit }, budget_id) {
      return await apiCall(Api.load_budget_by_id(budget_id), 'LOAD_BUDGET_BY_ID');
    },

    async ADD_BUDGET({ commit }, theBudget) {
      return await apiCall(Api.add_budget(theBudget), 'ADD_BUDGET', 201);
    },

    async UPD_BUDGET({ commit }, { id, theBudget }) {
      return await apiCall(Api.upd_budget(id, theBudget), 'UPD_BUDGET');
    },

    async DEL_BUDGET({ commit }, budget_id) {
      return await apiCall(Api.del_budget(budget_id), 'DEL_BUDGET');
    },

    async APPROVE_BUDGET({ commit }, budget_id) {
      return await apiCall(Api.approve_budget(budget_id), 'APPROVE_BUDGET', 201);
    },

    async CANCEL_BUDGET_APPROVAL({ commit }, budget_id) {
      return await apiCall(Api.cancel_budget_approval(budget_id), 'CANCEL_BUDGET_APPROVAL', 201);
    },

  },

  mutations: {
    SET_BUDGET_LIST(state, list) {
      state.list = [...list];
    },
  },

  getters: {
    getBudgetAmount: (state) => (budget_id) => {
      return state.list.find((el) => el.budget_id == budget_id)
    },

    // Should we delete it as it is slower than fitering in the component?
    budgetsByRegion: (state) => (region) => {
      return !region ? state.list : state.list.filter(el => el.fk_region === region)
    },

    budgetsByYear: (state) => (year) => {
      return !year ? state.list : state.list.filter(el => el.year === year)
    },

    budgetsByRegionAndYear: (state) => (region, year, unapprovedOnly = false) => {
      // Only for periodic maintenance
      return state.list.filter(el =>
        el.fk_region === region &&
        el.year === year && //&& [4, 6].includes(el.fk_work_category)
        (!unapprovedOnly || el.approved === false)
      )
        .sort((a, b) => a.work_category.localeCompare(b.work_category, undefined, { sensitivity: 'base' }))
    },

    budgetsByCategory: (state, getters) => (region, year, category) => {
      const res = getters.budgetsByRegionAndYear(region, year)
      return !category ? res : res.filter(el => el.fk_work_category === category)
    },

    categoriesInBudget: (state, getters) => (region, year) => {
      const res = getters.budgetsByRegionAndYear(region, year)
        .map(item => ({ id: item.fk_work_category, description: item.work_category }))
      res.unshift({ id: 0, description: '' })
      return res
    },

    budgetSummary: (state, getters) => (region, year) => {
      const res = getters.budgetsByRegionAndYear(region, year)
      const totalRecords = res.length
      const totalKGS = !totalRecords ? null : res.reduce((sum, item) => sum + item.amount_kgs, 0)
      const totalUSD = !totalRecords ? null : res.reduce((sum, item) => sum + item.amount_usd, 0)
      const totalTreatments = res.reduce((sum, item) => sum + item.treatment_count, 0)
      const totalApproved = !totalRecords ? null : res.reduce((sum, item) => sum + (item.approved ? item.amount_kgs : 0), 0)
      const approved = !totalRecords ? false : totalKGS === totalApproved

      return {
        totalRecords,
        totalKGS,
        totalUSD,
        totalTreatments,
        totalApproved,
        approved
      }
    },

    allocatedBudgetByRegionAndYear: (state, getters) => (region, year) => {
      const res = getters.budgetsByRegionAndYear(region, year)

      // Return object like {periodic: 1000, routine: 100, approved: true}
      return res.reduce((acc, el) => {
        acc[el.fk_work_category == 8 || el.fk_work_category == 10 ? 'periodic' : 'routine'] = el.amount_kgs
        acc['approved'] = Boolean(el.approved)
        return acc;
      }, {});
    },
  }
}