/*
File: api/coords.js
Description: API connections for the async functions for drawing maps.
*/
import Api_ref from '@/api'

const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

export default {
  // for drawing map
  load_map_roads_coords(region_id, road_id, deu_id, section_id) {
    //all_unpaved.push(all_paved)
    //  const res = { status: 200, data: all_coords }
    return { status: 200, data: [] }   // res
    /* 
      // Uncomment after Andrey copies all the data to the DB
       const lang = global.localStorage["mps-locale"]
        //    let apiStr = `/rdb/section/coords_zip?lang=${lang}`
        let apiStr = `/rdb/section/coords?lang=${lang}`
        if (region_id) apiStr += `&region_id=${region_id}`
        if (road_id) apiStr += `&road_id=${road_id}`
        if (deu_id) apiStr += `&deu_id=${deu_id}`
        if (section_id) apiStr += `&section_id=${section_id}`
    
        return Api.get(apiStr, getHeaders());
        */
  },

  load_windowed_map_coords(payload) {
    const queryString = Object.entries(payload)
      .filter(([, value]) => value !== undefined && value !== null) // Убираем пустые значения
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`) // Кодируем значения
      .join('&');

    return Api.get(`/rdb/section/coords_x_y?${queryString}`, getHeaders());
  }
}