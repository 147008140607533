/*
File: api/inventory.js
Description: API connections for the async functions for Inventory Types and Events.
*/
import Api_ref from '@/api'

const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getLangQuery = () => {
  const lang = global.localStorage["mps-locale"] || "en";
  return `?lang=${lang}`;
};

export default {
  /*---------  Inventory Types  ---------------------*/
  load_inventory_types_list() {
    return Api.get(`/rdb/inventory_type/all${getLangQuery()}`, getHeaders());
  },

  load_rdb_inventory_type_by_id(inventory_type_id) {
    return Api.get(`/rdb/inventory_type/${inventory_type_id}`, getHeaders());
  },

  add_rdb_inventory_type(newInventoryType) {
    return Api.post(`/rdb/inventory_type`, newInventoryType, getHeaders());
  },

  upd_rdb_inventory_type(inventory_type_id, theInventoryType) {
    return Api.put(`/rdb/inventory_type/${inventory_type_id}`, theInventoryType, getHeaders());
  },

  /*---------  Inventory Events  ---------------------*/
  load_inventory_events_list() {
    return Api.get(`/rdb/inventory_type_event/all${getLangQuery()}`, getHeaders());
  },

  load_rdb_inventory_event_by_id(inventory_type_event_id) {
    return Api.get(`/rdb/inventory_type_event/${inventory_type_event_id}`, getHeaders());
  },

  add_rdb_inventory_event(newInventoryEvent) {
    return Api.post(`/rdb/inventory_type_event`, newInventoryEvent, getHeaders());
  },

  upd_rdb_inventory_event(inventory_type_event_id, theInventoryEvent) {
    return Api.put(`/rdb/inventory_type_event/${inventory_type_event_id}`, theInventoryEvent, getHeaders());
  },
}