/* 
File: traffic.js
Description: API connections for the async functions for Traffic sites and Traffic installation.
*/
import Api_ref from '@/api'

const { Api, type_json } = Api_ref.props;

const getHeaders = () => ({
  headers: {
    ...type_json
  }
});

const getLangQuery = () => {
  const lang = global.localStorage["mps-locale"] || "en";
  return `?lang=${lang}`;
};

export default {
  /*--------- Traffic sites ---------------------*/
  load_traffic_site_all() {
    return Api.get(`/rdb/traffic_site/all${getLangQuery()}`, getHeaders());
  },

  load_traffic_site_by_id(id) {
    return Api.get(`/rdb/traffic_site/${id}${getLangQuery()}`, getHeaders());
  },

  add_traffic_site(traffic_site) {
    return Api.post(`/rdb/traffic_site`, traffic_site, getHeaders());
  },

  upd_traffic_site(id, traffic_site) {
    return Api.put(`/rdb/traffic_site/${id}`, traffic_site, getHeaders());
  },

  del_traffic_site(traffic_site_id) {
    return Api.delete(`/rdb/traffic_site/${traffic_site_id}`, getHeaders());
  },

  /*--------- Traffic installation ---------------------*/
  load_traffic_installation_all() {
    return Api.get(`/rdb/traffic_installation/all`, getHeaders());
  },

  load_traffic_installation_by_id(id) {
    return Api.get(`/rdb/traffic_installation/${id}`, getHeaders());
  },

  add_traffic_installation(traffic) {
    return Api.post(`/rdb/traffic_installation`, traffic, getHeaders());
  },

  upd_traffic_installation(id, traffic) {
    return Api.put(`/rdb/traffic_installation/${id}`, traffic, getHeaders());
  },
}