import Api from '@/api/users_groups'
import { apiCall, handleApiCall } from '@/store/helpers/apicall_helpers';

const acc_level = [
  { id: 0, name_en: 'DDH', name_ru: 'ДДХ' },
  { id: 1, name_en: 'RD/UAD', name_ru: 'РО/УАД' },
  { id: 2, name_en: 'DEU', name_ru: 'ДЭУ' }
]

export default {
  state: {
    list: [],
  },

  actions: {
    async LOAD_GROUP_LIST({ commit }) {
      return await handleApiCall({
        request: Api.load_group_list(),
        caller: 'LOAD_GROUP_LIST',
        commit,
        mutation: 'SET_GROUP_LIST'
      });
    },

    async LOAD_REGION_LIST({ commit }) {
      return await apiCall(Api.load_region_list(), 'LOAD_REGION_LIST');
    },

    async LOAD_ACC_LEVEL_LIST({ commit }) {
      return acc_level
    },

    async LOAD_GROUP_BY_ID({ commit }, id) {
      return await apiCall(Api.load_group_by_id(id), 'LOAD_GROUP_BY_ID');
    },

    async ADD_GROUP({ commit }, group) {
      return await apiCall(Api.add_group(group), 'ADD_GROUP', 201);
    },

    async UPD_GROUP({ commit }, payload) {
      return await apiCall(Api.upd_group(id, group), 'UPD_GROUP');
    },

    async DEL_GROUP({ commit }, id) {
      return await apiCall({ request: Api.del_group(id), caller: 'DEL_GROUP', });
    }
  },

  mutations: {
    SET_GROUP_LIST(state, list) {
      state.list = [...list]
    },
  },

  getters: {
    groupById: (state) => (id) => {
      const res = (!id) ? '' : state.list.find(item => item.id === id)
      const lang = global.localStorage["mps-locale"]
      return (!res) ? '' : res[`name_${lang}`]
    }
  }
}
